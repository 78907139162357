@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import "assets/styles/mixins";

.component{
    width: 100%;
    background-color: var(--background-primary);
    font-family: "Inter", sans-serif;
    @media only screen and (max-width: 600px) {
        display: flex;
        justify-content: center;
 
         }

    .main {
        @media only screen and (max-width: 600px) {
            display: flex;
            justify-content: center;

     
             }

        &_info {
            color: var(--White, #FFF);

            span:first-child {
                font-size: 64px;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 20px;

                @media (max-width: 842px){
                    font-size: 30px;
                    margin: 0;
                  }
            }
        }

        &_layer {
           display: none !important;

           img {
            max-width: 100%;
            width: 98%;
           }

           @include viewport-medium-min-width {
               display: flex !important;
           }
        }

        &_form {
            
    
            &_privacy {
              
                color: var(--White, #FFF);
                .custom_checkbox {
                    background-color: unset !important;
                    border-radius: 5px;
                    border: 1px solid var(--White, #FFF);


                }

                span {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 130%;

                    a {
                        color: var(--White, #FFF) !important;
                        text-decoration-line: underline;
                    }
                }

            }
            .privacy_error {
                width: 100%;
                margin-top: 0.25rem;
                font-size: .875em;
                color: #dc3545;
            }
        }
        ul {
            list-style: none;
            padding: 0;
            @include viewport-medium-600-max-width {
             flex-direction: row;
             flex-wrap: wrap;
             gap: 5px;
            }
  
            span {
                font-size: var(--bs-body-font-size) !important;
                line-height: 100%;
                font-weight: 400 !important;

                a {
                    cursor: pointer !important;
                }
            }
          }
    }
}

