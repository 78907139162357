@import "./variables";
@import "./mixins";
@import "../fonts/DM-sans/stylesheet";

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  // background-color: var(--background-body);
  color: var(--color-black-0);
  font-weight: normal;
  font-size: 16px;
  font-family: var(--font-family-primary);
  line-height: 145%;
}

section {
  position: relative;
  // z-index: 0;
}

.contentPositioner {
  max-width: var(--max-width-positioner);
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--content-positioner-padding-horizontal-large);
  padding-left: var(--content-positioner-padding-horizontal-large);


  @include viewport-xlarge-max-width {
    padding-right: var(--content-positioner-padding-horizontal-medium);
    padding-left: var(--content-positioner-padding-horizontal-medium);
  }
}

.contentPositionerTop {
  padding-top: var(--content-positioner-padding-top);

  @include viewport-xlarge-max-width {
    padding-top: var(--content-positioner-padding-top-max);
  }
}

.contentPositionerVertical {
  padding-top: var(--content-positioner-padding-vertical);
  padding-bottom: var(--content-positioner-padding-vertical);

  @include viewport-medium-max-width {
    padding-top: var(--content-positioner-padding-vertical-min);
    padding-bottom: var(--content-positioner-padding-vertical-min);
  }
}

.scrollLock {
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;

  &--small-viewport {
    white-space: normal;

    @include viewport-s-max-width {
      white-space: nowrap;
    }
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  clip-path: inset(100%);
}

ul {
  margin: 0;
  padding: 0;
}

a, a:active, a:hover {
  text-decoration: none;
}
