/* Viewport - ограничиваясь максимальной шириной
// @mixin viewport-xs-max-width {
//   @media screen and (max-width: (430px)) {
//     @content;
//   }
// } */

@mixin viewport-s-max-width {
  @media screen and (max-width: (767px)) {
    @content;
  }
}

@mixin viewport-medium-600-max-width {
  @media screen and (max-width: (600px)) {
    @content;
  }
}

@mixin viewport-medium-600-min-width {
  @media screen and (min-width: (600px)) {
    @content;
  }
}

@mixin viewport-medium-900-min-width {
  @media screen and (max-width: (900px)) {
    @content;
  }
}

@mixin viewport-medium-940-min-width {
  @media screen and (min-width: (940px)) {
    @content;
  }
}

@mixin viewport-medium-940-max-width {
  @media screen and (max-width: (940px)) {
    @content;
  }
}

@mixin viewport-medium-1000-max-width {
  @media screen and (max-width: (1000px)) {
    @content;
  }
}

@mixin viewport-medium-max-width {
  @media screen and (max-width: (1200px)) {
    @content;
  }
}

@mixin viewport-medium-min-width {
  @media screen and (min-width: (1200px)) {
    @content;
  }
}

@mixin viewport-xlarge-min-width {
  @media screen and (min-width: (1400px)) {
    @content;
  }
}

@mixin viewport-xlarge-max-width {
  @media screen and (max-width: (1400px)) {
    @content;
  }
}

@mixin viewport-xxlarge-max-width {
  @media screen and (max-width: (1600px)) {
    @content;
  }
}