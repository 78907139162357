@import "assets/styles/mixins";

.main {

    img {
        max-width: 100%;
    }

    &_back {

        & > div {
            z-index: 1;
            position: fixed;
            top: 110px;
            
            @include viewport-medium-940-max-width { 
                top: 90px;
            }

            @include viewport-medium-600-max-width { 
                top: 152px;
            }
        }
    }

    p {
        font-size: 40px;
        //font-weight: 700;
        line-height: 130%;
        margin: 0 0 50px 0;

        @include viewport-medium-940-max-width { 
            font-size: 25px;
            margin: 20px 0;
        }
    }

    span {
        color: rgb(24 20 45 / 70%);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    &_picture {
        img:last-child {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            border-radius: 15px;
        }
    }

    &_content {
        max-width: 934px;
        margin: auto;
        font-family: 'DM Sans', sans-serif;
        
        ul {
            list-style: none;

            .description {
                span:first-child {
                    color: var(--Black, #000);
                    font-size: 24px;
                    font-weight: 700;
                }

                span:last-child { 
                    color: rgb(0 0 0 / 80%);
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 32px;
                }

                a{
                    color: #b96ad9;
                    text-decoration: underline;

                    span {
                        color: #b96ad9;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &_content_from_api {
        span {
            font-size: inherit;
        }
        p {
            margin-bottom: 10px;
            line-height: 1.2;
            font-size: 16px;

            span {
                line-height: inherit;
            }
        }
    }
}