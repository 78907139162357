@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import "assets/styles/mixins";

.component {
    background-color: var(--background-white);
    
    .main {
        font-family: "Inter", sans-serif;

        .title {
            color: var(--Branding-Colors-Secondary, #0D111C);
            font-size: 48px;
            font-weight: 700;

            @include viewport-medium-600-max-width {
                font-size: 30px;
              }
        }
        #questions {
            margin-top: 3.5vw;

            .question {
                color: var(--Branding-Colors-Secondary, #0D111C);
                font-size: 18px;
                font-weight: 600;
                line-height: 145.023%;
                letter-spacing: 0.27px;

                @include viewport-medium-600-max-width {
                    font-size:15px;
                  }
            }

            .multy_answer {

                color: var(--Grey-100, #3F4956);
                font-size: 15px;
                font-weight: 400;
                line-height: 145.023%;
                letter-spacing: 0.225px;

                @include viewport-medium-600-max-width {
                    padding: 0;
                  }
            }

            #accordion_item {
                cursor: pointer;
                border: none;
                padding: 16px 0 32px 0;
                border-bottom: 1px solid rgb(13 17 28 / 20%) !important;

                
                .minus_icon::after {
                    background-image: url("../../assets/media/minus.svg") !important;
                }

                .plus_icon::after {
                    background-image: url("../../assets/media/plus.svg") !important;
                }

                .accordion_icon {
                    width: auto;
                    background-color: unset !important;
                    border: none !important;
                    box-shadow: none !important;
                    padding: 0 !important;
                    align-items: baseline;
                }

            }
        }

        &_media {
            img {
                max-width: 100%;
            }
        }

        &_usefuls {
            p {
                color: #020710;
                text-align: center;
                font-size: 48px;
                font-weight: 700;
                line-height: 52.8px;
                letter-spacing: 0.1px;
                text-transform: capitalize;

                @include viewport-medium-600-max-width { 
                    font-size: 30px;
                }
            }
        }
    }
}