.act {
  color: black;
  font-weight: bolder;
}

.mobileMenu {
  width: 70%;
  max-width: 400px;
  background-color: white;
  height: 100vh;
  left: -90.3vw;
  padding: 0 20px;
  position: absolute;
  top: 0;
  transition: 0.5s;
  overflow: auto;

  &.active {
    left: 0;
    transition-delay: 0.5s;
  }

  ul {
    background-color: white;
    padding: 20px;
    flex-direction: column;
    gap: 4px;
    display: flex;

    li {
      padding: 5px;
      list-style-type: none;
      padding: 6px;
      cursor: pointer;

      a {
        color: black;
        list-style-type: none;
        text-decoration: none;
        display: inline-block;
        padding: 6px 0;
        width: 100%;
      }
    }
  }

  .logo {
    margin-top: 20px;
    margin-bottom: 5px;
    width: 200px;
  }
}
