@import "assets/styles/mixins";

.main {
    font-family: 'DM Sans', sans-serif;
    &_back {
        padding-top: 31px;
        top: 78px;
        position: fixed;
        z-index: 1;
        width: 100vw;
        background: white;

        img {
            margin-left: 0px;
            @include viewport-medium-940-max-width { 
                margin-left: 0;
            }
        }

        @include viewport-medium-940-max-width { 
            padding-top: 0px;
            left: 0;
            top: 160px;
            p { 
                margin: 0 0 10px 0 !important;
            }
        }
    }

    p {
        font-size: 40px;
        font-weight: 700;
        line-height: 130%;
        margin: 50px 0;
    }

    span {
        color: rgb(24 20 45 / 70%);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    &_picture {
        background-image: url('../../assets/media/blog.jpg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center bottom;
        padding-top: 30.25%;
        border-radius: 7px;
        img:last-child {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @include viewport-medium-600-max-width {
            display: none;
        }
    }

    &_content {
        padding: 0 333px;

        li::marker {
            color: #7F00E2;
            font-size: 1.5em;
            font-family: 'Arial', sans-serif;
        }
    }

    &_share {
        span {
            color: #000;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
        }
    }

    &_usefuls {
        @include viewport-medium-600-max-width {
            margin-top: 50px;
        }
        span {
            color: #020710;
            text-align: center;
            font-size: 48px;
            font-weight: 700;
            line-height: 52.8px;
            letter-spacing: 0.1px;
            text-transform: capitalize;

            @include viewport-medium-600-max-width { 
                font-size: 30px;
            }
        }
    }
}