.main {
    min-height: 384px;

    &_media {
       img {
        max-width: 100%;
        max-height: 228px;
       }
    }

    span:first-child {
        color: var(--Black, #000);
        font-size: 20px;
        font-weight: 600;
        text-align: start;
    }

    span:last-child {
        color: rgb(0 0 0 / 70%);
        font-size: 14px;
        font-weight: 400;
        line-height: 21.75px;
        text-align: start;
    }
}

.blog_menu {
    border-radius: 7px;
    background: var(--White, #FFF);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.06);
}