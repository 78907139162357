.main {
    min-height: 384px;

    &_media {
        border-radius: 7px;
        background: var(--White, #FFF);
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.06);

       img {
        min-height: 170px;
        max-width: 100%;
       }
    }

    &_info {
        overflow: hidden;

        span:first-child {
            color: var(--Black, #000);
            font-size: 20px;
            font-weight: 600;
            text-align: start;
        }
        span:last-child {
            color: rgb(0 0 0 / 70%);
            font-size: 14px;
            font-weight: 400;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.45;
            -webkit-line-clamp: 5;
            padding-right: 1em;
        }
    }
}