@import "assets/styles/mixins";

.component {
  background-color: var(--background-white);

  .main {

    &_text_info {

      span:first-child {
        color: var(--Secondary, #18142D);
        font-size: 48px;
        font-weight: 700;
        line-height: 131%;

        @include viewport-medium-600-max-width {
         font-size: 30px;
        }
      }

      span:last-child {
        color: rgba(24, 20, 45, 0.70);
        max-width: 594px;
      }
    }

    &_services {
      margin-top: 60px;

      @include viewport-medium-600-max-width { 
        margin-top: 40px;
      }
      
      ul {
        list-style: none;
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 1.5rem;
        padding: 0;
      }
    }
  }
}