@import "assets/styles/mixins";

.blocks_numbers {
  position: relative; 
  width: 60px;
  height: 60px;

  @include viewport-medium-600-max-width {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 20px;
  }

  img {
    width: 60px;

    @include viewport-medium-600-max-width {
      width: 45px;
    }
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--White, #FFF);
    font-size: 36px;
    font-weight: 700;
    line-height: 130%;

    @include viewport-medium-600-max-width {
      top: 50%;
      left: 50%;
      line-height: 100%;
      font-size: 25px;
    }
  }
}

.info_content {
    hr {
      height: 1px;
      margin: 0;
      background: #A4A3B2;
    }

    span:first-of-type {
        font-size: 36px;
        font-weight: 500;
        line-height: 130%;

        @include viewport-medium-600-max-width {
          font-size: 25px;
        }
    }

    span:last-child {
        color: var(--Secondary, #18142D);
        opacity: 0.7;
    }
}