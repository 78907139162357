@import "assets/styles/mixins";

.aboutLayer{
    max-width: 98%;
    @include viewport-medium-600-max-width {
        display: none;
      }
}
.component {
    background-color: var(--background-secondary);
    padding-top: 150px;
    padding-bottom: 0;

    .experience {
        --bs-gutter-x: 9.591vw;

        &_globally {

            span {
                color: var(--Secondary, #18142D);
                font-size: 48px;
                font-weight: 700;
                line-height: 131%;
                max-width: 843px;
                margin-bottom: 5vw;
                @include viewport-medium-900-min-width {
                    font-size: 30px;
                  }
            }
            &_content {
                img {
                    max-width: 100%;
                }
            }
        }

        &_types {

            div {
                padding: 0 43px 18px 0;
                border-bottom: 1px solid #A4A3B2;
                line-height: normal;

                span:first-child {
                    color: var(--Secondary, #18142D);
                    font-weight: 500;
                    font-size: 24px;
                }

                span:nth-child(2) {
                    color: var(--Primary, #7F00E2);
                    font-weight: 700;
                    font-size: 64px;

                    @include viewport-s-max-width {
                        font-size: 30px;
                      }
                }

                span:last-child {
                    color: rgba(24, 20, 45, 0.70);
                }
            }
        }
    }
    
    .consultancy {
        margin-top: 70px;
        background-color: var(--background-primary);
        color: var(--White, #FFF);

        &_content {
            padding: 6vw 4vw;

            div {
                max-width: 940px;

                span:first-child {
                    font-size: 64px;
                    font-weight: 400;
                    line-height: 72px;
                    text-transform: capitalize;

                    @media (max-width: 842px){
                        font-size: 30px;
                        line-height: 45px;
                      }
                }

                 span:nth-child(2) {
                    font-size: 69px;
                    font-weight: 900;
                    line-height: 72px;
                    text-transform: capitalize;

                    @include viewport-medium-900-min-width {
                        font-size: 30px;
                        line-height: 30px;
                      }
                 }

                 span:nth-child(3) {
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 72px;
                    text-transform: capitalize;

                    @include viewport-medium-900-min-width {
                        font-size: 30px;
                        line-height: 30px;
                      }
                 }

                 span:last-child {
                    color: var(--Yellow, #FFC93B);
                    font-size: 67px;
                    font-weight: 700;
                    line-height: 72px;
                    text-transform: capitalize;

                    @include viewport-medium-900-min-width {
                        font-size: 30px;
                        line-height: 45px;
                      }
                 }
            }

            &_touch {
                top: -40px;

                @include viewport-medium-max-width {
                    top: unset;
                }

                @include viewport-medium-600-max-width {
                    top: -40px;
                }

                img:first-child {
                    top: -120px;
                    left: 167px;
                    width: 100px;
                    @include viewport-medium-max-width {
                        width: 85px;
                        top: -100px;
                        left: 177px;
                    }

                    @include viewport-medium-600-max-width { 
                        width: 60px;
                        top: -72px;
                        left: 210px;
                    }
                }

                img:last-of-type {
                    top: -28px;
                    left: 140px;
                    @include viewport-medium-600-max-width {
                        width: 65px;
                        top: -20px;
                        left: 170px;
                    }
                }
            }
            
        }
    }
}