@import "assets/styles/mixins";

.main {
     background-image: url('../../assets/media/background.svg');
    .info_content {
        max-width: 737px;
        .title {
            font-size: 64px;
            font-weight: 700;
            line-height: 120%; 
            @media (max-width: 842px){
                font-size: 30px;
              }
        }

        .text {
            color: rgba(24, 20, 45, 0.70);
            font-weight: 400;
            line-height: 145%; 
            max-width: 601px;
        }

        @include viewport-s-max-width {
            .title {
                font-size: 30px;
            }

            .text { 
                font-size: 14px;
            }
        }
    }

    .navLink {
        outline: none;
        text-decoration: none;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
    }

    .media_content {
        max-width: 526px;
        img {
            width: 100%;
        }
    }

}