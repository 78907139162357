@import "assets/styles/mixins";
a {
  color: unset;
  text-decoration: none;

  &:hover {
    color: unset;
  }

  .main {
      min-height: 350px;
      padding: 1.5vw 3.7vw;
      border-radius: 7px;
      background: var(--White, #FFF);
      box-shadow: 0px 10px 25.4px 0px rgba(0, 0, 0, 0.09);
      
      img{
          @include viewport-medium-600-max-width {
            width: 80%;
            padding: 20px;
          }
      }
      
      span:first-of-type {
          font-size: 30px;
          font-weight: 700;
          line-height: normal;
          text-align: center;
          
          @include viewport-medium-600-max-width {
              font-size: 27px;
            }
      }

      span:last-of-type {
          color: var(--Secondary, #18142D);
          text-align: center;
      }
  }
}