.component {
    background-color: var(--background-secondary);

    .main {
        display: flex;
        flex-direction: column;
        gap: 3.5vw;
        
        span {
            color: #020710;
            text-align: center;
            font-size: 48px;
            font-weight: 700;
            line-height: 52.8px;
            letter-spacing: 0.1px;

            @media (max-width: 842px){
                font-size: 30px;
              }
        }
    }

    ul {
        list-style: none;
        display: flex;
        justify-content: center;
        padding: 0;
    }
}