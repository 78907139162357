@import "assets/styles/mixins";

a {
  cursor: pointer;
}
.active {
  font-weight: bolder;
 
}

.component {
  padding-top: 30px;
  padding-bottom: 30px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  max-width: unset !important;
  z-index: 1;

  @include viewport-medium-940-max-width { 
    padding-top: 20px;
    padding-bottom: 10px;
  }

  @include viewport-medium-600-max-width {
    padding-top: 20px;
    padding-bottom: 35px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include viewport-medium-940-max-width {
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    .opened_menu {
      transform: rotate(90deg);
      transition: all 0.6s;
    }

    .menu {
      width: 40px;
      cursor: pointer;

      @include viewport-medium-940-min-width {
        display: none;
      }
    }

    .desktopButton {
      @include viewport-medium-940-max-width {
        display: none;
      }

      a {
        text-decoration: none;
        color: black;
        cursor: pointer;
      }
    }

    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      margin: 0;
      padding: 0;

      @include viewport-medium-940-max-width {
        display: none;
      }

      li {
        list-style-type: none;
        color: rgb(0, 0, 0);

        a, label{
          color: #333;
          font-size: 20px;
          text-decoration: none;

          @include viewport-xlarge-max-width {
            font-size: 18px;
          }

          @include viewport-medium-1000-max-width {
            font-size: 16px;
          }

          &:hover {
            color: #27252567;
          }
        }
      }
    }
  }

  .mobileButton {
    display: flex;
    justify-content: end;
    @include viewport-medium-940-min-width {
      display: none;
    }
  }
}