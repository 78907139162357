.custom_input {
    border-radius: 7px;
    font-weight: 400;
    line-height: normal;
  }

  input::placeholder, textarea::placeholder {
    color: rgb(250 250 250 / 70%) !important;
  }

  .custom_input:focus {
    box-shadow: unset !important;
    border: 1px solid #fff;
    color: #fff;
  }

  .disabled {
    background: #272435 !important;
    border: unset;
  }

  .readonly {
    background: #0E0B1E !important;
    color: #CFCED2 !important;
  }

  .hovered:hover {
    background: #0A0815;
    border: 1px solid #fff;
    box-shadow: none;
  }

  .custom_input:not(:empty) {
    color: #FFFFFF;
    border: 1px solid #575562;
  }

  .form-control.is-invalid {
    background-image: none ;
    border: 1px solid #F21A1A;
  }

  .form-control.is-invalid:focus {
    border: 1px solid #F21A1A;
    box-shadow: none;
  }

  .custom_input::placeholder {
    color: #9F9DA5;
  }

  .custom_input:-webkit-autofill,
  .custom_input:-webkit-autofill:hover,
  .custom_input:-webkit-autofill:focus,
  .custom_input:-webkit-autofill:active{
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  .password_input:-webkit-autofill,
  .password_input:-webkit-autofill:hover,
  .password_input:-webkit-autofill:focus,
  .password_input:-webkit-autofill:active{
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  .invalid_feedback {  
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
  }

  .custom_checkbox {
    background-color: unset !important;
    border-radius: 5px;
    border: 1px solid var(--White, #FFF);
}