:root {
  /* Colors */
  --color-black-0: #000000;
  --color-black-1: #0D0D0D;
  --color-black-2: #000003;
  --color-secondary: #FAFAFA;
  --color-white: #FFFFFF;
  --color-blue-0: #02E2F0;
  --color-blue-1: #2484F2;
  --color-pink: #DF54F5;
  --color-yellow: #D0F20C;
  --color-green: #6AFF8B;
  --color-red: #F24B3D;
  --color-red-error: #F21A1A;
  --color-primary: #7F00E2;


  /* Fonts */
  --font-family-primary: "DM Sans Regular", "Arial", sans-serif;

  /* Backgrounds */
  --background-primary: var(--color-primary);
  --background-white: var(--color-white);
  --background-secondary: var(--color-secondary);
  --background-header: var(--color-black-2);
  --background-footer: var(--color-black-0);
  --linear-gradient-primary: linear-gradient(90deg, #02E2F0 0%, #2484F2 100%);
  --background-overlay: rgba(0, 0, 0, 0.50);


  /* Values */
  --transition-default: 0.3s ease;
  --box-shadow-default: 0px 2px 10px rgb(255 255 255 / 32%);
  --max-width-positioner: 1800px;
  --content-positioner-padding-horizontal-large: 200px;
  --content-positioner-padding-horizontal-medium: 50px;
  --content-positioner-padding-horizontal-min: 10px;
  --content-positioner-padding-top: 70px;
  --content-positioner-padding-top-max: 118px;
  --content-positioner-padding-vertical: 60px;
  --content-positioner-padding-vertical-min: 40px;
  --filter-blur: blur(28.5px);
  --border-radius-primary: 24px;
}
