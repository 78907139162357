@import "assets/styles/mixins";
@keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

.popup {
    color: black;
    display: none;
    position: absolute;
    top: 64%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    background: rgb(255 255 255/ 100%);
    padding: 2px 10px;
    border-radius: 5px;
    opacity: 1;
    animation: fadeOut 3s;
    animation-delay: 2s;

    @media only screen and (max-width: 600px) { 
      width: 100%;
    }

    .title {
      color: #FFC93B;
      font-weight: 700;
      font-size: 18px;
    }
  }
  
  .popup.open {
    display: block;
  }

  .popup.closed {
    opacity: 0;
  }