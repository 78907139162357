@import "../../assets/styles/mixins.scss";

.custom_component {
    
       @include viewport-medium-600-max-width {
        display: flex;
        justify-content: center;
      }


    .main {
        min-height: 400px;
        background-image: url('../../assets/media/aboutUs.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        box-sizing: content-box;
        padding-bottom: 50px;

        @include viewport-medium-max-width {
          min-height: 520px;
        }
        
        @include viewport-xlarge-min-width {
          min-height: 520px;
        }

        @include viewport-s-max-width {
            display: flex;
            justify-content: center;
            font-size: 15px;
            min-height: 400px;
          }

        .content {
            padding: 40px 60px;

            @include viewport-medium-600-max-width {
                height: 90%;
              }

            &_people_info {
                span:first-of-type {
                    color: var(--Primary, #7F00E2);
                    font-size: 20px;
                    font-weight: 500;
                    line-height: normal;
                    text-align: start;

                    @include viewport-medium-600-max-width {
                        font-size: 17px;
                      }
                }

                span:last-of-type {
                    color: var(--Shades-of-Grey-400, #7B7A8F);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-align: start;

                    @include viewport-medium-600-max-width {
                        font-size: 15px;
                      }
                }
            }

            &_feedback {
                span {
                    color: rgba(24, 20, 45, 0.75);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 145%;

                    @include viewport-medium-600-max-width {
                        font-size: 13px;
                      }
                }
            }
        }
    }
}