@import "assets/styles/mixins";
.main {
    background-color: var(--background-white);
    &_back {
        position: fixed;
        top: 78px;
        z-index: 1;
        background-color: #fff;
        div {
            margin-left: 200px;
        }
        @include viewport-medium-600-max-width { 
            left: 47px;
            top: 149px;
        }
    }

    .text {
        max-width: 935px;
        margin: 0 auto;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
}