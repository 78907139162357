@import "assets/styles/mixins";

.main  {
    font-family: 'DM Sans', sans-serif;
    &_back {

        & > div {
            z-index: 1;
            position: fixed;
            top: 100px;

            @include viewport-medium-600-max-width {
                top: 162px;
            }
        }
    }

    &_bunner {
        background-image: url('../../assets/media/projects.jpg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center bottom;
        padding-top: 30.25%;
        border-radius: 7px;

        @include viewport-medium-600-max-width {
            padding-top: 52.25%;
            background-position: center;
            display: none;
        }
    }
}