.dropdown {
    position: relative;
  
    &_label {
      cursor: pointer;
      display: block;
      color: #333;
      font-size: 22px;

      @media only screen and (max-width: 940px) {
        font-size: 17px;
      }
    }
  
    &_header {
      padding: 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    &_arrow {
      font-size: 13px;
      transition: transform 0.3s ease;
      margin-top: 4px;
  
      &.open {
        transform: rotate(180deg);
      }
    }
  
    &_list {
      padding: 10px 20px;
      position: absolute;
      top: 60px;
      left: -15px;
      right: 0px;
      background-color: rgb(127 0 226 / 98%);
      z-index: 1000;
      min-width: 300px;
      overflow-y: auto;
      border-radius: 5px;
      gap: 0px !important;

      @media only screen and (max-width: 940px) {
        top: 40px;
        min-width: unset;
        position: unset;
      }
    }
  
    &_item {
      padding: 10px;
      cursor: pointer;
      font-size: 20px;
      color: #fff !important;

      @media only screen and (max-width: 940px) {
        font-size: 16px;
        color: #333 !important;
        padding: 0 !important;
      }

      &:hover {
        box-shadow: 1px 1px 10px 5px rgba(123, 21, 201, 0.98);

        @media only screen and (max-width: 940px) {
          box-shadow: unset;
        }
      }
    }
  }