@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@import "assets/styles/mixins";

.component {
  background-color: var(--background-footer);
  font-family: "Inter", sans-serif;
  color: var(--White, #f6ececc7);

  .main {
    &_content {
      margin: 0 0 15px 0;
      padding: 2.7vw 0;

      &_media {
        img {
          width: 300px;
          max-width: 100%;

          @include viewport-medium-600-max-width {
            width: 200px;
          }
        }

        span {
          font-weight: 400;
          line-height: 24px;
          max-width: 527px;
        }
      }

      &_info {
        // gap: 4vw;
        
        @include viewport-medium-600-max-width {
          flex-wrap: wrap;
          justify-content: center;
        }

        p {
          font-size: 20px;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 1.3vw;
          text-align: start;

          @include viewport-medium-600-max-width {
            font-size: 20px;
          }
        }

        ul {
          list-style: none;
          padding: 0;
          @include viewport-medium-600-max-width {
           flex-direction: row;
           flex-wrap: wrap;
           gap: 5px;
          }

          li {
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            a {
              cursor: pointer;
            }
          }
        }
      }
    }

    &_copyright {
      font-size: 12px;
      font-weight: 400;
      line-height: 19.5px;
      opacity: 0.7;
      padding: 0 0 15px 0;
    }
  }

  a {
    display: inline-block;
    padding: 4px 0;
    text-decoration: none;
    color: var(--White, #f6ececc7);
  }
}