@import "assets/styles/mixins";

.projetcs {
    width: 100%;
    background-color: #fff;

    .container {
    background-color: var(--background-primary);
    max-height: 731px;
    
    @include viewport-s-max-width { 
        max-height: unset;
    }

    .ideas {
        padding: 0 244px;

        @include viewport-s-max-width { 
            padding: 0 80px;
        }

        .title {
            color: var(--White, #FFF);
            font-size: 48px;
            font-weight: 700;
            line-height: 130%;

            @include viewport-medium-600-max-width{
                font-size: 30px;
            }
        }

        .info_content {
            max-width: 441px;

           > span {
                color: var(--Shades-of-Grey-50, #FAFAFA);
                opacity: 0.7;
                font-size: 16px;
                font-weight: 400;
                line-height: 145%;
            }
            .see_more {
                cursor: pointer;
                color: #FFF;
            }
        }

        .ideas_container {
            margin-top: 81px;

            @include viewport-medium-600-max-width {
                margin-top: 20px;
            }
        }
    }

    img {
        max-width: 100%;
    }
    }
    
    .development {
        margin-top: 11vw;

        .content{
            background-color: black;
        }

        &_title {
            font-size: 48px;
            font-weight: 700;
            line-height: 130%;
            margin-bottom: 6vw;
            @include viewport-medium-600-max-width {
               font-size: 30px;
              }
        }

        ul {
            list-style: none;
            --bs-gutter-x: 6rem;
            padding-left: 0;
            
            @include viewport-medium-600-max-width {
                font-size: 16px;
               }
        }
    }
}
